// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-about-page-jsx": () => import("./../../../src/templates/AboutPage.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-archive-page-jsx": () => import("./../../../src/templates/ArchivePage.jsx" /* webpackChunkName: "component---src-templates-archive-page-jsx" */),
  "component---src-templates-blog-index-page-jsx": () => import("./../../../src/templates/BlogIndexPage.jsx" /* webpackChunkName: "component---src-templates-blog-index-page-jsx" */),
  "component---src-templates-blog-post-page-jsx": () => import("./../../../src/templates/BlogPostPage.jsx" /* webpackChunkName: "component---src-templates-blog-post-page-jsx" */),
  "component---src-templates-chapter-page-jsx": () => import("./../../../src/templates/ChapterPage.jsx" /* webpackChunkName: "component---src-templates-chapter-page-jsx" */),
  "component---src-templates-characters-page-jsx": () => import("./../../../src/templates/CharactersPage.jsx" /* webpackChunkName: "component---src-templates-characters-page-jsx" */),
  "component---src-templates-contact-page-jsx": () => import("./../../../src/templates/ContactPage.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-privacy-policy-page-jsx": () => import("./../../../src/templates/PrivacyPolicyPage.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-jsx" */)
}

