import React from 'react';
import { Helmet } from 'react-helmet';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <script src="https://aphid.nihilnegativum.com/script.js" data-site="MBEZRYDG" defer></script>
      </Helmet>

      {element}
    </>
  );
};
